<template>
  <travio-center-container :pageTitle="pageTitle">
    <vx-card>
      <div v-if="isEditForm" class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Credit Id</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input disabled class="w-full" v-model="form.id" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Amount*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" type="number" min="0" name="Amount" v-model.number="form.amount" v-validate="'required|decimal'" />
          <span class="text-danger text-sm">{{ errors.first('Amount') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Currency*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select :value="selectedCurrency" :disabled="readOnly" name="Currency" @input="setSelectedCurrency" v-validate="'required'" :options="currencyOptions" />
          <span class="text-danger text-sm">{{ errors.first('Currency') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Credit Reference</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.creditReference" v-validate="'max:50'" name="Credit Reference" />
          <span class="text-danger text-sm">{{ errors.first('Credit Reference') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Notes</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-textarea 
            :disabled="readOnly"
            name="Message"
            counter="500"
            maxlength="500"
            rows="4" 
            v-model="form.notes"
            v-validate="'max:500'"
            class="w-full"/>
          <span class="text-danger text-sm">{{ errors.first('Notes') }}</span>
        </div>
      </div>

      <div v-if="isEditForm" class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Is Active</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <!-- <vs-checkbox class="inline-flex" v-model="dummy2">Enable 2FA</vs-checkbox> -->
          <vs-switch :disabled="readOnly" v-model="form.isActive" />
        </div>
      </div>
      <div v-if="!readOnly" class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div v-if="!readOnly" class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mr-4">Cancel</vs-button>
        <vs-button v-if="userHasPermission('company_settings_edit')" @click='submitCreate'>Save</vs-button>
      </div>
      <div v-if="readOnly" class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="success" class="mr-4">Credit Limit List</vs-button>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';
import companyApplicationStore from '@/app/company-applications/companyApplicationStore.js'

export default {
  components: {
    vSelect
  },
  props: {
    readOnly: { type: Boolean, default: true },
  },
  data () {
    return {
      form: new Form({
        id: 0,
        amount: '',
        currencyCode: '',
        creditReference: '',
        notes: '',
        isActive: null
      }),
      pageTitle: '',
      currencyOptions: [
        { label: "USD", code: "USD" },
        { label: "GBP", code: "GBP" },
        { label: "EUR", code: "EUR" },
      ],
      selectedCurrency: null,
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    isEditForm() {
      return this.$route.params.creditId > 0 ? true : false;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  async mounted () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
    }
    const companyDetailsPromise = this.$http.get(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}`)
    const formDataPromise = this.$http.get(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}/credit/formdata`)
    if (this.isEditForm) {
      this.$vs.loading()
      const creditLimitPromise =  this.$http.get(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}/credit/${this.$route.params.creditId}`)
      const [companyDetails, creditLimit, formData] = await Promise.all([companyDetailsPromise, creditLimitPromise, formDataPromise])
      this.form = Object.assign(this.form, creditLimit.data)
      this.currencyOptions = formData.data.currencies
      this.selectedCurrency = this.currencyOptions.find(x => x.code == creditLimit.data.currencyCode) || {}
      this.pageTitle = 'Edit Credit Limit for Company ' + ( companyDetails.data && companyDetails.data.name )
      this.$vs.loading.close()
    } else {
      this.$vs.loading()
      const [companyDetails, formData] = await Promise.all([companyDetailsPromise, formDataPromise])
      this.pageTitle = 'Create New Credit Limit for Company ' + ( companyDetails.data && companyDetails.data.name )
      this.currencyOptions = formData.data.currencies
      this.$vs.loading.close()
    }
    
    
  },
  methods: {
    setSelectedCurrency (value) {
      this.form.currencyCode = value ? value.code : null
      this.selectedCurrency = value;
    },
    submitCreate () {
      this.$validator.validateAll().then(validationResult => {
        if (!validationResult) {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
          return
        }

        if (!this.isEditForm) {
          this.$vs.loading()
          this.form.post(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}/credit`)
            .then(response => {
              this.$_notifySuccess('Company credit successfully saved');
              this.handleCancel()
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          // Put request
          this.$vs.loading()
          this.form.put(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}/credit`)
            .then(response => {
              this.$_notifySuccess('Company credit successfully saved');
              this.handleCancel()
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } 
      })
    },
    handleCancel () {
      this.$router.push({
        name: 'company-credit-limit',
        params: {
          applicationId: this.$route.params.applicationId,
          companyId: this.$route.params.companyId
        }
      })
    },
  }
}
</script>